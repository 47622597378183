<template>
    <div v-if="row.item" class="o365-body-row" :data-o365-rowindex="rowIndex" ref="rowRef"
        :class="[{ 'active': row.item.current && activeRows, 'selected': row.item.isSelected, 'dirty': row.item.hasChanges && row.item.disableSaving}, rowClass, { 'd-none': row.isLoading, 'o365-group-row': row.item.o_groupHeaderRow || row.item.o_hasDetails }]"
        :style="[{ height: row.rowHeight + 'px', 'transform': 'translateY(' + row.pos + 'px)' }, rowStyle]">
            <div class="o365-body-row-inner-container pinned-left"
                :style="{'min-width':dataGridControl.dataColumns.leftPinnedWidth+'px', 'max-width':dataGridControl.dataColumns.leftPinnedWidth+'px'}">
                <template v-for="(col) in leftColumns" :key="col.colId">
                    <template v-if="!col.hide && col.pinned === 'left'">
                        <ScopedCell :x="col.order" :y="rowIndex" :getSelectionClass="getSelectionClass">
                            <template #default="{selectionClass}">
                                <ODataGridBodyCell :col="col" :row="row" :colIndex="col.order" :disabled="!dataGridControl.state.allowUpdate"
                                    :selectionClass="selectionClass" isTable
                                    :handleSelection="(pEvent) => handleSelection(pEvent, row)"
                                    :is-active-edit-cell="activeEditLocation === `${container}_${col.order}_${rowIndex}`">
                                    <template #editor>
                                        <InlineCellEditor :ref="cmp => updateEditorRef(cmp, col.order + '_' + rowIndex)" :row="row?.item"
                                            :column="col" :gridContainer="viewportRef" :dataGridControl="dataGridControl"
                                            :rowIndex="rowIndex" />
                                    </template>
                                </ODataGridBodyCell>
                            </template>
                        </ScopedCell>
                    </template>
                </template>
            </div>
            <div class="o365-body-row-inner-container">
                <template v-for="(col) in centerColumns" :key="col.colId">
                    <template v-if="!col.hide && !col.pinned">
                        <ScopedCell :x="col.order" :y="rowIndex" :getSelectionClass="getSelectionClass">
                            <template #default="{selectionClass}">
                                <ODataGridBodyCell :col="col" :row="row" :colIndex="col.order" :disabled="!dataGridControl.state.allowUpdate"
                                    :selectionClass="selectionClass" isTable
                                    :handleSelection="(pEvent) => handleSelection(pEvent, row)"
                                    :is-active-edit-cell="activeEditLocation === `${container}_${col.order}_${rowIndex}`">
                                    <template #editor>
                                        <InlineCellEditor :ref="cmp => updateEditorRef(cmp, col.order + '_' + rowIndex)" :row="row?.item"
                                            :column="col" :gridContainer="viewportRef" :dataGridControl="dataGridControl"
                                            :rowIndex="rowIndex" />
                                    </template>
                                </ODataGridBodyCell>
                            </template>
                        </ScopedCell>
                    </template>
                </template>
            </div>
            <div class="o365-body-row-inner-container pinned-right"
                :style="{'min-width':dataGridControl.dataColumns.rightPinnedWidth+'px', 'max-width':dataGridControl.dataColumns.rightPinnedWidth+'px'}">
                <template v-for="(col) in rightColumns" :key="col.colId">
                    <template v-if="!col.hide && col.pinned === 'right'">
                        <ScopedCell :x="col.order" :y="rowIndex" :getSelectionClass="getSelectionClass">
                            <template #default="{selectionClass}">
                                <ODataGridBodyCell :col="col" :row="row" :colIndex="col.order" :disabled="!dataGridControl.state.allowUpdate"
                                    :selectionClass="selectionClass" isTable
                                    :handleSelection="(pEvent) => handleSelection(pEvent, row)"
                                    :is-active-edit-cell="activeEditLocation === `${container}_${col.order}_${rowIndex}`">
                                    <template #editor>
                                        <InlineCellEditor :ref="cmp => updateEditorRef(cmp, col.order + '_' + rowIndex)" :row="row?.item"
                                            :column="col" :gridContainer="viewportRef" :dataGridControl="dataGridControl"
                                            :rowIndex="rowIndex" />
                                    </template>
                                </ODataGridBodyCell>
                            </template>
                        </ScopedCell>
                    </template>
                </template>
            </div>
        <slot name="overlay" :row="row.item"></slot>
    </div>
</template>

<script lang="ts">

const ScopedCell = defineComponent({
    name: 'ScopedCell',
    props: ['getSelectionClass','x','y'],
    setup(props, ctx) {
        const selectionClass = computed(() => {
            return props.getSelectionClass(props.x, props.y);
        });
        const selValue = ref([]);
        watch(selectionClass, (newVal, oldVal) => {
            selValue.value = newVal;
        }, {
            immediate: true
        });
        return () => ctx.slots.default({selectionClass: selValue.value});
    }
});
</script>

<script setup lang="ts">
import type { ScrollItem } from 'o365.vue.composables.VirtualScroll.ts';
import type DataGridContorl from 'o365.controls.DataGrid.ts';
import type { Ref } from 'vue';

import { ODataGridBodyCell } from 'o365.vue.components.DataGrid.helpers.jsx';
import InlineCellEditor from 'o365.vue.components.DataGrid.InlineCellEditor.vue';
import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';
import { computed, inject, onUpdated, ref, watch, defineComponent } from 'vue';

const props = defineProps<{
    row: ScrollItem,
    rowIndex: number,
    rowClass: any,
    rowStyle: any,
    getSelectionClass: (x: number, y: number) => string | undefined,
    updateEditorRef: (cmp: any, loc: string) => void,
    container: string,
    activeEditLocation?: string | null,
    viewportRef: any,
    isTable?: boolean,
    activeRows?: boolean,
}>();

const dataGridControl = inject<Ref<DataGridContorl>>(dataGridControlKey);
if (dataGridControl == null || dataGridControl.value == null) {
    throw new Error('DataGridBodRow must be inside a DataGrid');
} 

const leftColumns = computed(() => {
    return dataGridControl.value.dataColumns.leftColumns;
});
const centerColumns = computed(() => {
    return dataGridControl.value.dataColumns.centerColumns;
});
const rightColumns = computed(() => {
    return dataGridControl.value.dataColumns.rightColumns;
});

function handleSelection(pEvent: MouseEvent, pRow: ScrollItem) {
    dataGridControl.value.handleScrollItemMultiSelect(pEvent, pRow);
}
</script>

<style>
    .o365-body-row-inner-container {
        display: flex;
        flex-direction: row;
    }
</style>